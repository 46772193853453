import { type ReactNode, createContext, useCallback, useState } from 'react'

import { type SanityProductFragment } from '@data/sanity/queries/types/product'

interface ProductQuickAdd {
  isOpen: boolean
  product: SanityProductFragment | null
}

interface ProductQuickAddContextProps {
  productQuickAdd: ProductQuickAdd
  openProductQuickAdd: (product: SanityProductFragment) => void
  closeProductQuickAdd: () => void
}

interface ProductQuickAddContextProviderProps {
  children: ReactNode
}

export const ProductQuickAddContext =
  createContext<ProductQuickAddContextProps>({
    productQuickAdd: {
      isOpen: false,
      product: null,
    },
    openProductQuickAdd: () => null,
    closeProductQuickAdd: () => null,
  })

export const ProductQuickAddContextProvider = ({
  children,
}: ProductQuickAddContextProviderProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [product, setProduct] = useState<SanityProductFragment | null>(null)

  const openProductQuickAdd = useCallback((product: SanityProductFragment) => {
    setProduct(product)
    setIsOpen(true)
  }, [])

  const closeProductQuickAdd = useCallback(() => {
    setIsOpen(false)

    // Wait for animation to finish
    setTimeout(() => {
      setProduct(null)
    }, 200)
  }, [])

  return (
    <ProductQuickAddContext.Provider
      value={{
        productQuickAdd: {
          isOpen,
          product,
        },
        openProductQuickAdd,
        closeProductQuickAdd,
      }}
    >
      {children}
    </ProductQuickAddContext.Provider>
  )
}
