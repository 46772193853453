import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import {
  type SanityProductGalleryPhoto,
  type SanityProductVariantFragment,
  type SanityProductVariantOption,
  type SanityProductVariantProduct,
} from '@data/sanity/queries/types/product'
import { hasObject } from './helpers'

/**
 * Gets product gallery photos for selected variant.
 */
export const getProductGalleryPhotos = (
  photosets: SanityProductGalleryPhoto[],
  variant?: SanityProductVariantFragment,
): SanityImageFragment[] => {
  const variantPhotoset = photosets.find(({ forOption }) => {
    const option = !!forOption
      ? {
          name: forOption.split(':')[0],
          value: forOption.split(':')[1],
        }
      : {}

    return option.value && variant && hasObject(variant.options, option)
  })

  if (variantPhotoset?.photos && variantPhotoset.photos.length > 0) {
    return variantPhotoset.photos
  }

  return photosets.find(({ forOption }) => !forOption)?.photos ?? []
}

/**
 * Gets product listing thumbnail for selected variant using gallery photos.
 */
export const getProductListingThumbnail = (
  product: SanityProductVariantProduct,
  options: SanityProductVariantOption[],
) => {
  const listingPhotos = !!product.inheritCartPhotos
    ? product.listingPhotos
    : product.cartPhotos
  const defaultListingPhoto = listingPhotos?.find(
    (listingPhoto) => !listingPhoto.forOption,
  )
  const variantListingPhoto = listingPhotos?.find((listingPhoto) => {
    if (!listingPhoto.forOption) {
      return false
    }

    const option: Partial<SanityProductVariantOption> = {
      name: listingPhoto.forOption.split(':')[0],
      value: listingPhoto.forOption.split(':')[1],
    }

    return option.value && hasObject(options, option)
  })
  const listingPhoto = variantListingPhoto ?? defaultListingPhoto

  const gallery = product.galleryPhotos?.find((gallery) => {
    if (!listingPhoto?.forOption) {
      return !gallery.forOption
    }

    return gallery.forOption === listingPhoto?.forOption
  })
  const galleryPhotos = gallery?.photos ?? []

  return galleryPhotos.find(
    (galleryPhoto) =>
      !listingPhoto || galleryPhoto.asset._id === listingPhoto.galleryImage,
  )
}

/**
 * Finds a specific photo from product gallery.
 */
export const getProductGalleryPhoto = (
  galleryPhotos: SanityProductGalleryPhoto[],
  forOption?: string,
  assetId?: string,
) => {
  if (!assetId) {
    return
  }

  // Get the default gallery or the gallery for given option
  const gallery = galleryPhotos.find((gallery) => {
    if (!forOption) {
      return !gallery.forOption
    }

    return gallery.forOption === forOption
  })

  return gallery?.photos?.find((photo) => photo.asset._id === assetId)
}
