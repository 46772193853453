import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

export interface DropdownOption {
  value: string
  title: string
}

export interface ErrorMessages {
  [key: string]: string
}

/**
 * Checks if an object is found in another array of objects.
 */
export function hasObject(records?: any[], values?: any[] | any) {
  if (!records) {
    return false
  }

  return records.some((object) => {
    for (const key in object) {
      if (
        values &&
        key in values &&
        object[key] != (values as Record<string, unknown>)[key]
      ) {
        return false
      }
    }

    return true
  })
}

/**
 * Keeps a number within a range.
 */
export function clampRange(value: number, min = 0, max = 1) {
  return value < min ? min : value > max ? max : value
}

/**
 * Parses required page request parameter.
 */
export function parseRequiredParameter<T = string>(
  parameter: string | string[],
) {
  if (Array.isArray(parameter)) {
    return parameter[0] as T
  }

  return parameter as T
}

/**
 * Parses optional page request parameter.
 */
export function parseOptionalParameter<T = string>(
  parameter?: string | string[] | null,
) {
  if (typeof parameter === 'undefined' || parameter === null) {
    return
  }

  if (Array.isArray(parameter) && parameter.length === 0) {
    return
  }

  return parseRequiredParameter<T>(parameter)
}

/**
 * Parses required page request parameter array.
 */
export function parseRequiredParameters<T = string>(
  parameter: string | string[],
) {
  if (Array.isArray(parameter)) {
    return parameter as T[]
  }

  return [parameter] as T[]
}

/**
 * Parses optional page request parameter array.
 */
export function parseOptionalParameters<T = string>(
  parameter?: string | string[] | null,
) {
  if (typeof parameter === 'undefined' || parameter === null) {
    return
  }

  if (Array.isArray(parameter) && parameter.length === 0) {
    return
  }

  return parseRequiredParameters<T>(parameter)
}

/**
 * Parses JSON string into an object.
 */
export const parseJson = (json: string): Record<string, unknown> => {
  try {
    return JSON.parse(json)
  } catch (_) {
    return {}
  }
}

/**
 * Compares numbers for sorting.
 */
export const compareNumbers = (number1: number, number2: number) =>
  number1 > number2 ? 1 : number1 < number2 ? -1 : 0

/**
 * Compares strings for sorting.
 */
export const compareStrings = (string1: string, string2: string) =>
  string1.localeCompare(string2)

/**
 * Determines if 2 variables are equal using JSON representation.
 */
export const isEqual = (variable1: unknown, variable2: unknown) =>
  JSON.stringify(variable1) === JSON.stringify(variable2)

/**
 * Generates all combinations from multiple arrays.
 * E.g., getAllCombinations(['a', 'b'], ['1', '2']) returns [['a', '1'], ['a', '2'], ['b', '1'], ['b', '2']].
 */
export const getAllCombinations = (...arrays: string[][]): string[][] => {
  const initialValue: string[][] = [[]]

  return [...arrays].reduce(
    (resultArrays, array) =>
      resultArrays
        .map((resultArray) =>
          array.map((arrayValue) => resultArray.concat(arrayValue)),
        )
        .reduce(
          (newResultArrays, arraysItem) => newResultArrays.concat(arraysItem),
          [],
        ),
    initialValue,
  )
}

/**
 * Generates a random string based on UUID.
 */
export const getRandomString = () => Buffer.from(uuidv4()).toString('base64')

/**
 * Gets published and draft document IDs from given ID.
 */
export const getDocumentIds = (id: string) => {
  const publishedId = id.replace(/^drafts\./, '')
  const draftId = `drafts.${publishedId}`

  return [publishedId, draftId] as const
}

/**
 * Detects touch device.
 */
export const useIsTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(false)

  useEffect(() => {
    setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0)
  }, [])

  return isTouchDevice
}

/**
 * Escapes a string to be using in regular expressions.
 * "$&" means the whole matched string.
 */
export const escapeRegExp = (string: string) =>
  string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

/**
 * Determines if JavaScript object is numeric.
 */
export const isNumeric = (object: any) => {
  const normalizedObject =
    typeof object === 'string' ? object.replace(/,/g, '') : object

  return (
    !isNaN(parseFloat(normalizedObject)) &&
    isFinite(normalizedObject) &&
    Object.prototype.toString.call(normalizedObject).toLowerCase() !==
      '[object array]'
  )
}

/**
 * Converts a string into a hash.
 */
export const stringToHash = (string: string) => {
  let hash = 0
  let chr: number

  if (string.length === 0) {
    return hash
  }

  for (let i = 0; i < string.length; i += 1) {
    chr = string.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0
  }

  return hash
}
