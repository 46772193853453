import { type ReactNode, createContext, useState } from 'react'

import {
  type SanityCombinedListingConfiguration,
  type SanityCombinedListingDefaultsConfiguration,
} from '@data/sanity/queries/types/product'
import {
  type SanityCombinedListingProductFragment,
  type SanityCombinedListingProductVariantFragment,
} from '@data/sanity/queries/types/product'
import {
  useCombinedListing,
  useMemoryStateManager,
  useUrlStateManager,
} from './hooks'
import {
  type CombinedListingProductType,
  type CombinedListingStateManagerType,
} from './types'

interface ProductCombinedListingContextProps {
  combinedListingMainProductId?: number
  combinedListingConfiguration?: SanityCombinedListingConfiguration
  combinedListingDefaultsConfiguration?: SanityCombinedListingDefaultsConfiguration
  combinedListingActiveProducts?: SanityCombinedListingProductFragment[]
  combinedListingActiveVariants?: SanityCombinedListingProductVariantFragment[]
  combinedListingActiveSize?: string
  combinedListingActivePassepartoutHoleSize?: string | null
  setCombinedListingStateManagerType: (
    stateManagerType: CombinedListingStateManagerType,
  ) => void
  setCombinedListingMainProductId: (productId: number) => void
  setCombinedListingConfiguration: (
    combinedListingConfiguration?: SanityCombinedListingConfiguration,
  ) => void
  setCombinedListingDefaultsConfiguration: (
    combinedListingDefaultsConfiguration?: SanityCombinedListingDefaultsConfiguration,
  ) => void
  addCombinedListingProduct: (productId: number) => void
  setCombinedListingSize: (size: string) => void
  setCombinedListingPassepartoutHoleSize: (
    passepartoutHoleSize: string | null,
  ) => void
  clearCombinedListingProductType: (type: CombinedListingProductType) => void
  clearCombinedListing: () => void
}

interface StringsContextProviderProps {
  children: ReactNode
}

export const ProductCombinedListingContext =
  createContext<ProductCombinedListingContextProps>({
    setCombinedListingStateManagerType: () => null,
    setCombinedListingMainProductId: () => null,
    setCombinedListingConfiguration: () => null,
    setCombinedListingDefaultsConfiguration: () => null,
    addCombinedListingProduct: () => null,
    setCombinedListingSize: () => null,
    setCombinedListingPassepartoutHoleSize: () => null,
    clearCombinedListingProductType: () => null,
    clearCombinedListing: () => null,
  })

export const ProductCombinedListingContextProvider = ({
  children,
}: StringsContextProviderProps) => {
  const [combinedListingStateManagerType, setCombinedListingStateManagerType] =
    useState<CombinedListingStateManagerType>('memory')
  const [combinedListingMainProductId, setCombinedListingMainProductId] =
    useState<number>()
  const [combinedListingConfiguration, setCombinedListingConfiguration] =
    useState<SanityCombinedListingConfiguration>()
  const [
    combinedListingDefaultsConfiguration,
    setCombinedListingDefaultsConfiguration,
  ] = useState<SanityCombinedListingDefaultsConfiguration>()

  // Combined listing state manager that saves parameters
  const urlStateManager = useUrlStateManager()
  const memoryStateManager = useMemoryStateManager()
  const stateManager =
    combinedListingStateManagerType === 'url'
      ? urlStateManager
      : memoryStateManager

  const [
    combinedListingActiveProducts,
    combinedListingActiveVariants,
    combinedListingActiveSize,
    combinedListingActivePassepartoutHoleSize,
    addCombinedListingProduct,
    setCombinedListingSize,
    setCombinedListingPassepartoutHoleSize,
    clearCombinedListingProductType,
    clearCombinedListing,
  ] = useCombinedListing(
    stateManager,
    combinedListingMainProductId,
    combinedListingConfiguration,
  )

  return (
    <ProductCombinedListingContext.Provider
      value={{
        combinedListingMainProductId,
        combinedListingConfiguration,
        combinedListingDefaultsConfiguration,
        combinedListingActiveProducts,
        combinedListingActiveVariants,
        combinedListingActiveSize,
        combinedListingActivePassepartoutHoleSize,
        setCombinedListingStateManagerType,
        setCombinedListingMainProductId,
        setCombinedListingConfiguration,
        setCombinedListingDefaultsConfiguration,
        addCombinedListingProduct,
        setCombinedListingSize,
        setCombinedListingPassepartoutHoleSize,
        clearCombinedListingProductType,
        clearCombinedListing,
      }}
    >
      {children}
    </ProductCombinedListingContext.Provider>
  )
}
