import cx from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import { useCallback, useContext, useEffect, useId, useState } from 'react'

import { counterAnimation } from '@lib/animate'
import { clampRange } from '@lib/helpers'
import { StringsContext } from '@lib/strings-context'

import Icon from '@components/icon'

interface ProductCounterProps {
  onUpdate: (newValue: number) => void
  defaultCount?: number
  max?: number
  isSmall?: boolean
  className?: string
}

const ProductCounter = ({
  onUpdate,
  defaultCount = 1,
  max,
  isSmall,
  className,
}: ProductCounterProps) => {
  const strings = useContext(StringsContext)

  const [lineQuantity, setLineQuantity] = useState(defaultCount)
  const [direction, setDirection] = useState(1)
  const [motionKey, setMotionKey] = useState('')
  const [isAnimating, setIsAnimating] = useState(false)

  const id = useId()

  const animateQuantity = useCallback(
    (amount: number, direction: number) => {
      const count = max ? clampRange(amount, 1, max) : amount

      // Bail if at edges
      if (count < 1 || (max && count > max)) {
        return
      }

      setIsAnimating(true)
      setDirection(direction)
      setMotionKey(count + (direction > 0 ? '-up' : '-down'))
      setLineQuantity(count)

      if (onUpdate) {
        onUpdate(count)
      }
    },
    [onUpdate, max],
  )

  const updateQuantity = useCallback(
    (amount: number) => {
      const count = max ? clampRange(amount, 1, max) : amount

      if (count < 1) {
        return
      }

      setIsAnimating(false)
      setLineQuantity(count)

      if (onUpdate) {
        onUpdate(count)
      }
    },
    [onUpdate, max],
  )

  useEffect(() => setLineQuantity(defaultCount), [defaultCount])

  return (
    <div className={className}>
      <div
        className={cx(
          'border input-border rounded-full inline-grid h-full grid-cols-[auto,auto,auto] text-input-text',
          {
            'p-2': !isSmall,
            'p-1': isSmall,
          },
        )}
      >
        <button
          aria-label={strings.productDecreaseQuantity}
          onClick={() => animateQuantity(lineQuantity - 1, -1)}
          className={cx(
            '-my-px w-10 h-10 inline-flex items-center justify-center text-lg rounded-full transition-colors duration-300 m-0 bg-transparent hover:bg-opacity-5 hover:bg-input-text',
          )}
        >
          <Icon name="Minus" id={`product-counter-${id}`} className="block" />
        </button>
        <div
          className={cx('text-sm relative overflow-hidden', {
            'w-12': !isSmall,
            'w-10': isSmall,
          })}
        >
          <AnimatePresence custom={direction}>
            <motion.div
              key={motionKey}
              initial={isAnimating ? 'hideR' : 'show'}
              animate="show"
              exit="hide"
              variants={counterAnimation}
              custom={direction}
              className="flex w-full h-full will-change-transform absolute inset-0 last:relative last:inset-auto"
            >
              <input
                aria-label={strings.productEnterQuantity}
                onChange={(event) => {
                  updateQuantity(parseInt(event.currentTarget.value, 10))
                }}
                onBlur={() => {
                  if (isNaN(lineQuantity)) {
                    updateQuantity(1)
                  }
                }}
                type="number"
                inputMode="numeric"
                min="1"
                value={lineQuantity ? lineQuantity : ''}
                className="relative bg-transparent border-0 rounded-none appearance-none p-0 w-full text-center text-current text-sm outline-none no-input-spinners"
              />
            </motion.div>
          </AnimatePresence>
        </div>
        <button
          aria-label={strings.productIncreaseQuantity}
          onClick={() => animateQuantity(lineQuantity + 1, 1)}
          className={cx(
            '-my-px w-10 h-10 inline-flex items-center justify-center text-lg rounded-full transition-colors duration-300 m-0 bg-transparent hover:bg-opacity-5 hover:bg-input-text',
          )}
        >
          <Icon name="Plus" id={`product-counter-${id}`} className="block" />
        </button>
      </div>
    </div>
  )
}

export default ProductCounter
