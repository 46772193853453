import {
  type Dispatch,
  type MouseEvent,
  type SetStateAction,
  useContext,
} from 'react'
import { useForm } from 'react-hook-form'
import cx from 'classnames'

import { type SanitySimplePortableText } from '@data/sanity/queries/types/content'
import { CartContext } from '@lib/cart/context'
import { DiscountContext } from '@lib/discount-context'
import { triggerInitiateCheckoutFacebookEvent } from '@lib/facebook'
import { type ErrorMessages } from '@lib/helpers'
import { type Locale } from '@lib/language'
import { LanguageContext } from '@lib/language-context'
import { ShopContext } from '@lib/shop-context'
import { StringsContext } from '@lib/strings-context'
import { SiteContext } from '@lib/site-context'

import Button, { ButtonVariant } from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import SimplePortableText from '@components/simple-portable-text'

/**
 * Adds locale param to URL.
 */
const addLocaleParamToUrl = (url: string, locale: Locale) => {
  const searchParams = new URLSearchParams(`locale=${locale}`)
  const newUrl = new URL(url)
  searchParams.forEach((value, key) => newUrl.searchParams.set(key, value))

  return newUrl.toString()
}

/**
 * Formats checkout URL by replacing host and adding linker param.
 */
const formatCheckoutUrl = async (
  checkoutUrl: string,
  locale: Locale,
  storeUrl?: string,
) => {
  let formattedCheckoutUrl = `${checkoutUrl}`

  // Update checkout URL to use custom domain name
  if (storeUrl) {
    formattedCheckoutUrl = formattedCheckoutUrl.replace(
      /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/g,
      storeUrl,
    )
  }

  formattedCheckoutUrl = addLocaleParamToUrl(formattedCheckoutUrl, locale)
  // TODO: Add Google Analytics linker param to URL.

  return formattedCheckoutUrl
}

interface CartSubmitProps {
  vatId: string
  comment: string
  terms?: SanitySimplePortableText
  setErrorMessages: Dispatch<SetStateAction<ErrorMessages>>
  storeUrl?: string
  onClick?: () => void
  className?: string
}

const CartSubmit = ({
  vatId,
  comment,
  setErrorMessages,
  storeUrl,
  onClick,
  terms,
  className,
}: CartSubmitProps) => {
  const { register, watch } = useForm()
  const { currencyCode } = useContext(ShopContext)
  const { cart, isCartUpdating, isCartSubmitting, submitCart } =
    useContext(CartContext)
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)
  const { cartDiscountItems } = useContext(DiscountContext)
  const { settings } = useContext(SiteContext)

  const acceptTermsRegister = register('acceptTerms')
  const hasAgreed = watch('acceptTerms')

  // Update checkout URL to use custom domain name
  const checkoutUrl = cart?.webUrl

  const goToCheckout = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    const { errors } = await submitCart({ vatId, comment })

    setErrorMessages((messages) => ({ ...messages, vatId: errors.vatId }))

    if (checkoutUrl && Object.entries(errors).length === 0) {
      onClick && onClick()

      const formattedCheckoutUrl = await formatCheckoutUrl(
        checkoutUrl,
        locale,
        storeUrl,
      )

      setTimeout(async () => {
        if (settings?.facebookEvents) {
          await triggerInitiateCheckoutFacebookEvent(
            locale,
            cart,
            cartDiscountItems ?? [],
            currencyCode,
          )
        }

        // Redirect to checkout
        window.location.href = formattedCheckoutUrl
      }, 0)
    }
  }

  return (
    <div className={cx('space-y-4', className)}>
      {terms && (
        <Checkbox formRegister={acceptTermsRegister}>
          <SimplePortableText className="rc" content={terms} />
        </Checkbox>
      )}
      <Button
        variant={ButtonVariant.FILLED}
        className="w-full"
        disabled={isCartUpdating || isCartSubmitting || (terms && !hasAgreed)}
        onClick={goToCheckout}
      >
        {isCartUpdating && strings.buttonUpdating}
        {isCartSubmitting && strings.buttonSubmitting}
        {!isCartUpdating && !isCartSubmitting && strings.cartSubmit}
      </Button>
    </div>
  )
}

export default CartSubmit
