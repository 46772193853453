import cx from 'classnames'
import { useContext, useMemo } from 'react'

import {
  type SanityProductVariantOption,
  type SanityProductVariantProduct,
} from '@data/sanity/queries/types/product'
import { useUser } from '@lib/auth'
import { getProductListingThumbnail } from '@lib/product-images'
import { StringsContext } from '@lib/strings-context'

import ProductCounter from '@blocks/product/product-counter'
import ProductPrice from '@blocks/product/product-price'
import Photo from '@components/photo'
import SimpleLink from '@components/simple-link'

interface CartItemProps {
  lineId: string
  price: number
  options: SanityProductVariantOption[]
  quantity: number
  product: SanityProductVariantProduct
  productUrl?: string
  onUpdateItem: (lineId: string, quantity: number) => void
  onRemoveItem: (lineId: string) => void
  onClose: () => void
  className?: string
}

const CartLineItem = ({
  lineId,
  price,
  options,
  quantity,
  product,
  productUrl,
  onUpdateItem,
  onRemoveItem,
  onClose,
  className,
}: CartItemProps) => {
  const strings = useContext(StringsContext)

  const { user } = useUser()

  const thumbnailImage = useMemo(
    () => getProductListingThumbnail(product, options),
    [options, product],
  )
  const itemComparePrice = useMemo(
    () =>
      user?.company?.percentDiscount
        ? price * (1 - user.company.percentDiscount / 100)
        : undefined,
    [price, user],
  )

  return (
    <div
      className={cx('flex gap-5 sm:gap-8 relative border-b pb-5', className)}
    >
      {!!thumbnailImage && typeof thumbnailImage === 'string' && (
        <figure className="flex-shrink-0 relative m-0 w-1/4 sm:w-1/3 max-w-[10rem] photo">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={thumbnailImage}
            sizes="(min-width: 768px) 400px, 35vw"
            alt={product.title}
            className="block overflow-hidden"
          />
        </figure>
      )}

      {!!thumbnailImage && typeof thumbnailImage !== 'string' && (
        <Photo
          image={thumbnailImage}
          sizes="(min-width: 768px) 400px, 35vw"
          className="flex-shrink-0 relative m-0 w-1/4 sm:w-1/3 max-w-[10rem]"
        />
      )}

      <div className="w-full flex flex-col justify-between gap-5">
        <div className="flex justify-between items-start gap-5 gap-x-8">
          <div className="space-y-1">
            {!!productUrl && (
              <SimpleLink
                href={productUrl}
                onClick={onClose}
                onBeforeInput={onClose}
                tabIndex={0}
                role="link"
              >
                <h4>{product.title}</h4>
              </SimpleLink>
            )}
            {!productUrl && <h4>{product.title}</h4>}

            <p>
              {options.map((option, index) => (
                <span key={index}>
                  <span className="font-medium">{option.name}:</span>{' '}
                  {option.value}
                  {index < options.length - 1 && ', '}
                </span>
              ))}
            </p>
          </div>

          <ProductPrice
            price={price}
            comparePrice={itemComparePrice}
            showDiscountPercent
            className="hidden xs:inline-flex xs:flex-col xs:justify-end"
          />
        </div>

        <div className="space-y-5">
          <div className="flex justify-between items-center gap-5">
            <ProductCounter
              defaultCount={quantity}
              onUpdate={(quantity) => onUpdateItem(lineId, quantity)}
              isSmall
            />

            <button
              onClick={() => onRemoveItem(lineId)}
              className={cx('text-sm font-medium hover:opacity-60')}
            >
              {strings.buttonRemove}
            </button>
          </div>

          <ProductPrice
            price={price}
            comparePrice={itemComparePrice}
            showDiscountPercent
            className="xs:hidden"
          />
        </div>
      </div>
    </div>
  )
}

export default CartLineItem
