import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useContext,
  useMemo,
} from 'react'

import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { type CartLineInput } from '@data/shopify/storefront/types'
import { useUser } from '@lib/auth'
import { DiscountContext } from '@lib/discount-context'
import { triggerAddToCartFacebookEvent } from '@lib/facebook'
import { type Locale } from '@lib/language'
import { getCombinedListingMergedLineItems } from '@lib/product/combined-listing'
import { getSanityClient } from '@lib/sanity/client'
import { getProductVariants } from '@lib/sanity/product-variant'
import { ShopContext } from '@lib/shop-context'
import { getTaxCartAttributes } from '@lib/shopify/cart'
import { getShopifyGlobalId } from '@lib/shopify/client'
import {
  addLineItemsToShopifyCart,
  removeLineItemsFromShopifyCart,
  updateLineItemsInShopifyCart,
  updateShopifyCartAttrbites,
  updateShopifyCartNote,
} from '@lib/shopify/graphql/cart'
import { StringsContext } from '@lib/strings-context'
import { CartContext } from './context'
import { updateCartDiscount, validateCart } from './helpers'
import {
  type Cart,
  type CartFormValues,
  type CartTotals,
  type CartVariantLineItem,
} from './types'

/**
 * Returns cart item count.
 */
export const useCartItemCount = () => {
  const { cart } = useContext(CartContext)
  const strings = useContext(StringsContext)

  return useMemo(() => {
    const lineItems = cart?.lineItems ?? []
    const mergedLineItems = getCombinedListingMergedLineItems(
      strings,
      lineItems,
    )

    return (
      mergedLineItems?.reduce(
        (total, lineItem) => total + lineItem.quantity,
        0,
      ) ?? 0
    )
  }, [cart, strings])
}

/**
 * Returns cart totals.
 */
export const useCartTotals = () => {
  const { cart } = useContext(CartContext)
  const { cartDiscountItems } = useContext(DiscountContext)

  const { user } = useUser()

  return useMemo(() => {
    const subtotal = cart?.subtotal ?? 0

    const automaticDiscount = cart?.automaticDiscount?.amount ?? 0
    const discountItemDiscount =
      cartDiscountItems?.reduce(
        (total, cartDiscountItem) =>
          total + cartDiscountItem.amount * cartDiscountItem.quantity,
        0,
      ) ?? 0
    const companyDiscountRate = user?.company?.percentDiscount
      ? user.company.percentDiscount / 100
      : undefined
    const companyDiscount =
      cart?.lineItems?.reduce(
        (total, lineItem) =>
          total +
          (companyDiscountRate ? lineItem.price * companyDiscountRate : 0),
        0,
      ) ?? 0

    const total = cart?.total ?? 0

    const cartTotals: CartTotals = {
      subtotal,
      automaticDiscount,
      discountItemDiscount,
      companyDiscount,
      total: total - automaticDiscount - discountItemDiscount - companyDiscount,
    }
    return cartTotals
  }, [cart, cartDiscountItems, user])
}

export const useAddItemsToCart = (
  site: SanitySiteFragment,
  cart: Cart,
  locale: Locale,
  setIsCartProductAdding: Dispatch<SetStateAction<number[] | boolean>>,
  setIsCartUpdating: Dispatch<SetStateAction<boolean>>,
  saveCart: (locale: Locale, cart?: Cart) => void,
  toggleCart: (newState: boolean) => void,
) => {
  const { shopifyStorefrontClient } = useContext(ShopContext)

  return useCallback(
    async (variantLineItems: CartVariantLineItem[]): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsCartProductAdding(variantLineItems.map((item) => item.id))
      setIsCartUpdating(true)

      // Get variant details from Sanity
      const sanityClient = getSanityClient()
      const variantIds = variantLineItems.map(
        (variantLineItem) => variantLineItem.id,
      )
      const productVariants = await getProductVariants(
        sanityClient,
        locale,
        variantIds,
      )

      const lines = variantLineItems.map((variantLineItem) => {
        const cartLineInput: CartLineInput = {
          merchandiseId: getShopifyGlobalId(
            'ProductVariant',
            variantLineItem.id,
          ),
          quantity: variantLineItem.quantity,
          attributes: variantLineItem.attributes ?? [],
        }
        return cartLineInput
      })
      const cartResponse = await addLineItemsToShopifyCart(
        shopifyStorefrontClient,
        cart.id,
        lines,
      )

      if (cartResponse.error) {
        setIsCartProductAdding(false)
        setIsCartUpdating(false)
        return false
      }

      if (site.generalSettings.facebookEvents) {
        productVariants.forEach(async (variant) => {
          await triggerAddToCartFacebookEvent(locale, variant)
        })
      }

      // Update cart discount codes
      const newCart = await updateCartDiscount(locale, cart.id)

      if (!newCart) {
        setIsCartProductAdding(false)
        setIsCartUpdating(false)
        return false
      }

      saveCart(locale, newCart)

      setIsCartProductAdding(false)
      setIsCartUpdating(false)
      toggleCart(false)

      return !!newCart
    },
    [
      cart.id,
      locale,
      saveCart,
      setIsCartProductAdding,
      setIsCartUpdating,
      shopifyStorefrontClient,
      site.generalSettings.facebookEvents,
      toggleCart,
    ],
  )
}

export const useUpdateCartItem = (
  cart: Cart,
  locale: Locale,
  setIsCartUpdating: Dispatch<SetStateAction<boolean>>,
  saveCart: (locale: Locale, cart?: Cart) => void,
) => {
  const { shopifyStorefrontClient } = useContext(ShopContext)

  return useCallback(
    async (ids: string[], quantity: number): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsCartUpdating(true)

      // Update cart line items
      const cartResponse = await updateLineItemsInShopifyCart(
        shopifyStorefrontClient,
        cart.id,
        ids.map((id) => ({ id, quantity })),
      )

      if (cartResponse.error) {
        return false
      }

      // Update cart discount codes
      const newCart = await updateCartDiscount(locale, cart.id)

      if (!newCart) {
        return false
      }

      saveCart(locale, newCart)

      setIsCartUpdating(false)

      return !!newCart
    },
    [cart.id, locale, saveCart, setIsCartUpdating, shopifyStorefrontClient],
  )
}

export const useRemoveItemFromCart = (
  cart: Cart,
  locale: Locale,
  setIsCartUpdating: Dispatch<SetStateAction<boolean>>,
  saveCart: (locale: Locale, cart?: Cart) => void,
) => {
  const { shopifyStorefrontClient } = useContext(ShopContext)

  return useCallback(
    async (ids: string[]): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsCartUpdating(true)

      // Remove line item from Shopify cart
      const cartResponse = await removeLineItemsFromShopifyCart(
        shopifyStorefrontClient,
        cart.id,
        ids,
      )

      if (cartResponse.error) {
        return false
      }

      // Update cart discount codes
      const newCart = await updateCartDiscount(locale, cart.id)

      if (!newCart) {
        return false
      }

      saveCart(locale, newCart)

      setIsCartUpdating(false)

      return !!newCart
    },
    [cart.id, locale, saveCart, setIsCartUpdating, shopifyStorefrontClient],
  )
}

export const useSubmitCart = (
  cart: Cart,
  setIsCartSubmitting: Dispatch<SetStateAction<boolean>>,
) => {
  const { countryCode, shopifyStorefrontClient } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  return useCallback(
    async (values: CartFormValues) => {
      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsCartSubmitting(true)

      // Validate cart form
      const { errors, vatIdCountryCode } = await validateCart(strings, values)

      if (cart?.id && Object.entries(errors).length === 0) {
        // Update cart attributes
        const taxCartAttributes = getTaxCartAttributes(
          values,
          vatIdCountryCode !== countryCode,
        )

        await updateShopifyCartAttrbites(shopifyStorefrontClient, cart.id, [
          ...taxCartAttributes,
        ])

        // Update cart note
        await updateShopifyCartNote(
          shopifyStorefrontClient,
          cart.id,
          values.comment ?? '',
        )
      }

      setIsCartSubmitting(false)

      return { errors }
    },
    [
      cart.id,
      countryCode,
      setIsCartSubmitting,
      shopifyStorefrontClient,
      strings,
    ],
  )
}
