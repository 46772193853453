import {
  type SanityCombinedListingProductFragment,
  type SanityProductOption,
} from '@data/sanity/queries/types/product'
import { isNumeric } from '@lib/helpers'
import { sizeOptionNames } from '@lib/product'

/**
 * Extracts and filters product size option values.
 */
export const getValidSizeOptionValues = (options: SanityProductOption[]) => {
  const sizeOption = options.find((option) =>
    sizeOptionNames.includes(option.name),
  )

  return (
    sizeOption?.values?.filter((value) => {
      if (!value) {
        return false
      }

      const valueParts = value.split('x')

      if (
        !valueParts[0] ||
        !valueParts[1] ||
        !isNumeric(valueParts[0]) ||
        !isNumeric(valueParts[1])
      ) {
        return false
      }

      return true
    }) ?? []
  )
}

/**
 * Filters products by matching size option values with given size.
 */
export const filterProductsBySize =
  (size?: string) => (product: SanityCombinedListingProductFragment) => {
    if (!size) {
      return true
    }

    const sizeOption = product.options.find((option) =>
      sizeOptionNames.includes(option.name),
    )

    if (!sizeOption) {
      return true
    }

    return sizeOption.values.some((value) => value === size)
  }
