import { type SanityStrings } from '@data/sanity/queries/types/site'
import { type AttributeInput } from '@data/shopify/storefront/types'
import { type LineItem } from '@lib/cart/types'
import { sizeOptionNames } from '@lib/product'
import { getLinkPageUrl } from '@lib/routes'
import {
  shopifyAttributeCombinedListingId,
  shopifyAttributeCombinedListingMainVariantId,
  shopifyAttributeCombinedListingPassepartoutHoleSize,
} from '@lib/shopify/cart'
import {
  type CombinedListingItemGroupMap,
  type CombinedListingProductType,
} from './types'

/**
 * Gets cart attributes for combined listing items.
 */
export const getCombinedListingCartAttributes = (
  combinedListingVariantIds: number[],
  combinedListingId: string,
  passepartoutHoleSize?: string | null,
): AttributeInput[] => {
  return [
    {
      key: shopifyAttributeCombinedListingId,
      value: combinedListingId,
    },
    {
      key: shopifyAttributeCombinedListingMainVariantId,
      value: combinedListingVariantIds[0]
        ? `${combinedListingVariantIds[0]}`
        : '',
    },
    {
      key: shopifyAttributeCombinedListingPassepartoutHoleSize,
      value: passepartoutHoleSize ?? '',
    },
  ]
}

/**
 * Gets combined listing ID from cart line items.
 */
const getCombinedListingId = (attributes: AttributeInput[]) => {
  const combinedListingIdAttribute = attributes.find(
    (attribute) => attribute.key === shopifyAttributeCombinedListingId,
  )

  return combinedListingIdAttribute?.value
    ? combinedListingIdAttribute.value
    : null
}

/**
 * Gets combined listing main variant ID from cart line items.
 */
const getCombinedListingMainVariantId = (attributes: AttributeInput[]) => {
  const combinedListingMainVariantIdAttribute = attributes.find(
    (attribute) =>
      attribute.key === shopifyAttributeCombinedListingMainVariantId,
  )

  return combinedListingMainVariantIdAttribute?.value
    ? Number(combinedListingMainVariantIdAttribute.value)
    : null
}

/**
 * Gets combined listing passepartout hole size from cart line items.
 */
const getCombinedListingPassepartoutHoleSize = (
  attributes: AttributeInput[],
) => {
  const combinedListingPassepartoutAttribute = attributes.find(
    (attribute) =>
      attribute.key === shopifyAttributeCombinedListingPassepartoutHoleSize,
  )

  return combinedListingPassepartoutAttribute?.value
    ? combinedListingPassepartoutAttribute.value
    : null
}

/**
 * Gets combined listing mapping with main variant ID and line items grouped by combined listing IDs.
 */
const getCombinedListingItemGroupMap = (lineItems: LineItem[]) => {
  const combinedListingItemGroupMap: CombinedListingItemGroupMap = {}

  lineItems.forEach((lineItem) => {
    const combinedListingId = getCombinedListingId(lineItem.attributes ?? [])
    const combinedListingMainVariantId = getCombinedListingMainVariantId(
      lineItem.attributes ?? [],
    )

    if (!combinedListingId || !combinedListingMainVariantId) {
      return
    }

    if (!combinedListingItemGroupMap[combinedListingId]) {
      combinedListingItemGroupMap[combinedListingId] = {
        mainVariantId: combinedListingMainVariantId,
        lineItems: [],
      }
    }

    combinedListingItemGroupMap[combinedListingId].lineItems.push(lineItem)
  })

  return combinedListingItemGroupMap
}

/**
 * Gets all combined listing product line IDs from main product line ID.
 */
export const getCombinedListingLineIds = (
  lineItems: LineItem[],
  lineId: string,
) => {
  const lineItem = lineItems.find((lineItem) => lineItem.lineId === lineId)
  const combinedListingId = getCombinedListingId(lineItem?.attributes ?? [])

  if (!lineItem || !combinedListingId) {
    return
  }

  const combinedListingItemGroupMap = getCombinedListingItemGroupMap(lineItems)
  const combinedListingItemGroup =
    combinedListingItemGroupMap[combinedListingId]

  return combinedListingItemGroup.lineItems.map(
    (combinedListingLineItem) => combinedListingLineItem.lineId,
  )
}

/**
 * Merges matching combined listing cart line items together.
 */
export const getCombinedListingMergedLineItems = (
  strings: SanityStrings,
  lineItems: LineItem[],
) => {
  const optionLabels: Record<CombinedListingProductType, string> = {
    frame: strings.combinedListingFrame,
    glass: strings.combinedListingGlass,
    backboard: strings.combinedListingBackboard,
    passepartout: strings.combinedListingPassepartout,
    spacer: strings.combinedListingSpacer,
  }
  const combinedListingItemGroupMap = getCombinedListingItemGroupMap(lineItems)

  // Remove combined listing products that are not the main product
  const filteredLineItems = lineItems.filter((lineItem) => {
    const combinedListingMainVariantId = getCombinedListingMainVariantId(
      lineItem.attributes ?? [],
    )

    return (
      !combinedListingMainVariantId ||
      lineItem.variantID === combinedListingMainVariantId
    )
  })

  // Convert secondary combined listing products into options for the main product
  return filteredLineItems.map((lineItem) => {
    const combinedListingId = getCombinedListingId(lineItem.attributes ?? [])
    const combinedListingGroup = combinedListingId
      ? combinedListingItemGroupMap[combinedListingId]
      : null

    if (!combinedListingId || !combinedListingGroup) {
      const productUrl = lineItem.product.slug.current
        ? getLinkPageUrl('product', lineItem.product.slug.current, {
            variant: `${lineItem.variantID}`,
          })
        : undefined

      return {
        ...lineItem,
        productUrl,
        isCombinedListing: false,
      }
    }

    // Price
    const combinedListingPrice = combinedListingGroup.lineItems.reduce(
      (total, combinedListingLineItem) => total + combinedListingLineItem.price,
      0,
    )

    // Product URL
    const productIds = combinedListingGroup.lineItems.map(
      (combinedListingLineItem) => combinedListingLineItem.product.productID,
    )
    const sizeOption = lineItem.options.find((option) =>
      sizeOptionNames.includes(option.name),
    )
    const combinedListingProductUrl = lineItem.product.slug.current
      ? getLinkPageUrl('product', lineItem.product.slug.current, {
          products: productIds.join(','),
          size: sizeOption?.value,
        })
      : undefined

    // Options with combined listing product details
    const options = [...lineItem.options]
    const otherLineItems = combinedListingGroup.lineItems.filter(
      (combinedListingLineItem) =>
        combinedListingLineItem.variantID !== lineItem.variantID,
    )
    const combinedListingPassepartoutHoleSize =
      getCombinedListingPassepartoutHoleSize(lineItem.attributes ?? [])

    otherLineItems.forEach((otherLineItem) => {
      // Glass, backboard, passepartout and spacer options
      options.push({
        // Get option name from product combined listing type
        name:
          otherLineItem.product.type !== 'none'
            ? optionLabels[otherLineItem.product.type]
            : '',
        // Get option value from product title or option title field
        value: otherLineItem.product.optionTitle ?? otherLineItem.product.title,
        position: options.length + 1,
      })

      // Passepartout hole size option
      if (
        otherLineItem.product.type === 'passepartout' &&
        combinedListingPassepartoutHoleSize
      ) {
        options.push({
          name: strings.combinedListingPassepartoutHoleSize,
          value: combinedListingPassepartoutHoleSize,
          position: options.length + 1,
        })
      }
    })

    const newLineItem: LineItem = {
      ...lineItem,
      price: combinedListingPrice,
      productUrl: combinedListingProductUrl,
      options,
      isCombinedListing: true,
    }
    return newLineItem
  })
}
