import { useRouter } from 'next/router'
import queryString from 'query-string'
import { useCallback, useMemo } from 'react'

import { parseOptionalParameter, parseOptionalParameters } from './helpers'

export interface Parameter {
  name: string
  value?: string | null
}

export interface UrlParameter {
  name: string
  value?: string | string[] | null
}

type UrlParameters = Record<string, string[] | undefined>

/**
 * Gets and updates multiple URL parameters.
 */
export const useUrlParameters = (defaultUrlParameters: UrlParameter[]) => {
  const router = useRouter()

  // Get current URL query parameters
  const urlParameters = useMemo<UrlParameter[]>(
    () =>
      defaultUrlParameters.map((parameter) => {
        if (!router.isReady) {
          return {
            name: parameter.name,
          }
        }

        return {
          name: parameter.name,
          value: parseOptionalParameter(router.query[parameter.name]) ?? null,
        }
      }),
    [defaultUrlParameters, router],
  )

  // Update URL query parameters on change
  const setUrlParameters = useCallback(
    (parameters: UrlParameter[]) => {
      // Remove parameters that match default parameters (with both key and value)
      const filteredUrlParameters = parameters.filter(
        (parameter) =>
          !defaultUrlParameters.some(
            (defaultUrlParameter) =>
              defaultUrlParameter.name === parameter.name &&
              defaultUrlParameter.value === parameter.value,
          ),
      )
      const parsedUrlParameters = filteredUrlParameters.reduce(
        (previousValue, filteredUrlParameter) => {
          const value =
            filteredUrlParameter.value &&
            Array.isArray(filteredUrlParameter.value)
              ? filteredUrlParameter.value
              : filteredUrlParameter.value?.split(',')

          return {
            ...previousValue,
            [filteredUrlParameter.name]: value,
          }
        },
        {} as UrlParameters,
      )
      const urlQueryString = queryString.stringify(parsedUrlParameters, {
        arrayFormat: 'comma',
      })

      // Replace current URL with new parameters
      const slugs = parseOptionalParameters(router.query.slug) ?? []
      const currentPath = slugs.join('/')
      const newUrl = `${currentPath}${urlQueryString ? `?${urlQueryString}` : ''}`

      router.replace(newUrl, undefined, {
        shallow: true,
        scroll: false,
      })
    },
    [defaultUrlParameters, router],
  )

  return [urlParameters, setUrlParameters] as const
}

/**
 * Converts URL parameters to generic parameters.
 */
export const urlParametersToParameters = (
  urlParameters: UrlParameter[],
): Parameter[] =>
  urlParameters.map((urlParameter) => ({
    name: urlParameter.name,
    value:
      urlParameter.value && Array.isArray(urlParameter.value)
        ? urlParameter.value.join(',')
        : urlParameter.value,
  }))

/**
 * Sets parameter value keeping existing parameter order.
 */
export const setParameter = (
  parameters: Parameter[],
  name: string,
  value: string | null,
) => {
  let newParameters = [...parameters]
  const hasProductsParameter = parameters.some(
    (parameter) => parameter.name === name,
  )

  if (!hasProductsParameter) {
    newParameters = [
      ...newParameters,
      {
        name,
        value,
      },
    ]
  }

  return newParameters.map((parameter) => {
    if (parameter.name !== name) {
      return parameter
    }

    return {
      name,
      value,
    }
  })
}
