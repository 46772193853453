import cx from 'classnames'
import {
  type ChangeEvent,
  type FocusEvent,
  type TextareaHTMLAttributes,
  useState,
} from 'react'

import { type CustomFormRegister } from './input-field/with-form-register'

interface TextAreaProps {
  formRegister: CustomFormRegister
  errorMessage?: string
  label?: string
  borderBottom?: boolean
  rows?: number
  inputClassName?: string
}

const TextArea = ({
  id,
  value,
  defaultValue = '',
  autoComplete,
  placeholder,
  label,
  borderBottom,
  errorMessage,
  formRegister,
  rows,
  className,
  inputClassName,
}: TextAreaProps & TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  const isManaged = typeof value !== 'undefined'
  const [currentValue, setCurrentValue] = useState(defaultValue)

  return (
    <div className={cx('grid', className)}>
      <div className={cx('flex flex-col relative text-left text-sm')}>
        {label && (
          <label htmlFor={id} className="font-medium mb-2">
            {label}
          </label>
        )}

        <textarea
          id={id}
          inputMode="text"
          autoComplete={autoComplete}
          ref={formRegister.ref}
          name={formRegister.name}
          rows={rows}
          value={isManaged ? value : currentValue}
          placeholder={placeholder}
          onBlur={(event: FocusEvent<HTMLTextAreaElement>) => {
            formRegister.onBlur(event)
            setCurrentValue(event.target.value)
          }}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
            formRegister.onChange(event)
            setCurrentValue(event.target.value)
          }}
          className={cx(
            'relative appearance-none w-full h-full py-3 leading-none bg-input-bg text-input-text',
            {
              'border-error': errorMessage,
              'border-input-border': !errorMessage,
              'mb-[1px] border-b': borderBottom,
              'px-4 border rounded': !borderBottom,
            },
            inputClassName,
          )}
        >
          {value}
        </textarea>

        {errorMessage && (
          <span role="alert" className="mt-2 font-medium text-xs text-error">
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  )
}

export default TextArea
