import { type SanityCombinedListingConfiguration } from '@data/sanity/queries/types/product'
import { type Parameter } from '@lib/parameters'
import {
  findCombinedListingProduct,
  getCombinedListingProductSelection,
} from './product'
import { type CombinedListingProductType } from './types'

/**
 * Adds product ID to product parameter value.
 */
export const addCombinedListingProductToParameter = (
  combinedListingConfiguration: SanityCombinedListingConfiguration,
  parameters: Parameter[],
  productId: number,
) => {
  const productSelection = getCombinedListingProductSelection(
    combinedListingConfiguration,
    parameters,
  )

  // Add new product ID
  const result = findCombinedListingProduct(
    combinedListingConfiguration,
    productId,
  )

  if (result) {
    productSelection[result.type] = result.product.productID
  }

  // Join all values
  const values = Object.values(productSelection).filter(Boolean) as number[]
  return values.length > 0 ? values.map((value) => `${value}`).join(',') : null
}

/**
 * Clears product of given type in product parameter value.
 */
export const clearCombinedListingProductTypeFromParameter = (
  combinedListingConfiguration: SanityCombinedListingConfiguration,
  parameters: Parameter[],
  type: CombinedListingProductType,
) => {
  const productSelection = getCombinedListingProductSelection(
    combinedListingConfiguration,
    parameters,
  )

  // Clear product
  productSelection[type] = null

  // Join all values
  const values = Object.values(productSelection).filter(Boolean) as number[]
  return values.length > 0 ? values.map((value) => `${value}`).join(',') : null
}
