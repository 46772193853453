import { type ChangeEvent, useState } from 'react'
import { type Noop, type RefCallBack } from 'react-hook-form'

import InputFieldWithManagedState, {
  type InputFieldWithManagedStateProps,
} from './with-managed-state'

interface InputChangeEvent {
  target: EventTarget & (HTMLInputElement | HTMLTextAreaElement)
  type?: string
}

type InputChangeHandler = (event: InputChangeEvent) => Promise<void | boolean>

type ChangeHandler = (event: ChangeEvent) => void

// Combination of ControllerRenderProps and ControllerRenderProps from react-hook-form
export interface CustomFormRegister {
  ref: RefCallBack
  name: string
  value?: string | number
  onChange: InputChangeHandler | ChangeHandler | Noop
  onBlur: InputChangeHandler | Noop
}

type InputFieldWithFormRegisterProps = Pick<
  InputFieldWithManagedStateProps,
  | 'type'
  | 'label'
  | 'placeholder'
  | 'max'
  | 'maxLength'
  | 'autoComplete'
  | 'errorMessage'
  | 'noBorder'
  | 'borderBottom'
  | 'className'
  | 'inputClassName'
  | 'children'
> & {
  formRegister: CustomFormRegister
  defaultValue?: string
}

const InputFieldWithFormRegister = ({
  formRegister,
  defaultValue = '',
  type,
  label,
  placeholder,
  max,
  maxLength,
  autoComplete,
  errorMessage,
  noBorder,
  borderBottom,
  className,
  inputClassName,
  children,
}: InputFieldWithFormRegisterProps) => {
  const [value, setValue] = useState(defaultValue)

  return (
    <InputFieldWithManagedState
      ref={formRegister.ref}
      type={type}
      name={formRegister.name}
      value={value}
      setValue={setValue}
      label={label}
      placeholder={placeholder}
      max={max}
      maxLength={maxLength}
      autoComplete={autoComplete}
      errorMessage={errorMessage}
      noBorder={noBorder}
      borderBottom={borderBottom}
      onChange={formRegister.onChange}
      onBlur={formRegister.onBlur}
      className={className}
      inputClassName={inputClassName}
    >
      {children}
    </InputFieldWithManagedState>
  )
}

export default InputFieldWithFormRegister
