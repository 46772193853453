import {
  type SanityCombinedListingConfiguration,
  type SanityCombinedListingProductFragment,
  type SanityCombinedListingProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { compareNumbers, parseOptionalParameter } from '@lib/helpers'
import { type Parameter } from '@lib/parameters'
import { sizeOptionNames } from '@lib/product'
import { getValidSizeOptionValues } from './size'
import {
  type CombinedListing,
  type CombinedListingProductResult,
  type CombinedListingProductType,
} from './types'

const combinedListingProductTypes: CombinedListingProductType[] = [
  'frame',
  'glass',
  'backboard',
  'passepartout',
  'spacer',
]

const combinedListingProductTypeWeights: Record<
  CombinedListingProductType,
  number
> = {
  frame: 1,
  glass: 2,
  backboard: 3,
  passepartout: 4,
  spacer: 5,
}

/**
 * Groups combined listing sub-products by size.
 */
export const getCombinedListingProductsBySize = (
  frameProducts: SanityCombinedListingProductFragment[],
  glassProducts: SanityCombinedListingProductFragment[],
  backboardProducts: SanityCombinedListingProductFragment[],
  passepartoutProducts: SanityCombinedListingProductFragment[],
  spacerProducts: SanityCombinedListingProductFragment[],
) => {
  const combinedListing: CombinedListing = {}

  const addProduct = (
    type: CombinedListingProductType,
    product: SanityCombinedListingProductFragment,
  ) => {
    const sizes = getValidSizeOptionValues(product.options)

    sizes.forEach((size) => {
      // Take sizes only from frame products
      if (!combinedListing[size] && type === 'frame') {
        combinedListing[size] = {
          frame: [],
          glass: [],
          backboard: [],
          passepartout: [],
          spacer: [],
        }
      }

      if (combinedListing[size]) {
        combinedListing[size][type].push(product)
      }
    })
  }

  // Add all products to the combined listing configuration starting with frame products
  frameProducts.forEach((product) => addProduct('frame', product))
  glassProducts.forEach((product) => addProduct('glass', product))
  backboardProducts.forEach((product) => addProduct('backboard', product))
  passepartoutProducts.forEach((product) => addProduct('passepartout', product))
  spacerProducts.forEach((product) => addProduct('spacer', product))

  return combinedListing
}

/**
 * Finds a product and its type in combined listing configuration by its ID.
 */
export const findCombinedListingProduct = (
  combinedListingConfiguration: SanityCombinedListingConfiguration,
  productId: number,
) =>
  combinedListingProductTypes.reduce<CombinedListingProductResult | null>(
    (result, type) => {
      if (result !== null) {
        return result
      }

      const product = combinedListingConfiguration[type]?.find(
        (product) => product.productID === productId,
      )

      if (!product) {
        return result
      }

      return {
        type,
        product,
      }
    },
    null,
  )

/**
 * Gets active products from combined listing configuration and products parameter.
 */
export const getCombinedListingActiveProducts = (
  combinedListingConfiguration: SanityCombinedListingConfiguration,
  activeProductsIds: number[],
) => {
  const results = activeProductsIds
    .map((productId) =>
      findCombinedListingProduct(combinedListingConfiguration, productId),
    )
    .filter((result) => !!result) as CombinedListingProductResult[]
  const sortedResults = results.sort((result1, result2) =>
    compareNumbers(
      combinedListingProductTypeWeights[result1.type],
      combinedListingProductTypeWeights[result2.type],
    ),
  )

  return sortedResults.map((result) => result.product)
}

/**
 * Gets active product variants from active products and size.
 */
export const getCombinedListingActiveVariants = (
  activeProducts: SanityCombinedListingProductFragment[],
  activeSize?: string,
) => {
  if (!activeSize) {
    return []
  }

  return activeProducts
    .map((product) =>
      product.variants?.find((variant) => {
        const sizeOption = variant.options.find((option) =>
          sizeOptionNames.includes(option.name),
        )

        return sizeOption?.value === activeSize
      }),
    )
    .filter(Boolean) as SanityCombinedListingProductVariantFragment[]
}

/**
 * Gets active product IDs from parameters.
 */
export const getCombinedListingActiveProductsIds = (
  parameters: Parameter[],
) => {
  const productsParameter = parameters.find(
    (parameter) => parameter.name === 'products',
  )
  const productsParameterValueString = parseOptionalParameter<string>(
    productsParameter?.value,
  )
  const activeProductsIds = productsParameterValueString
    ?.split(',')
    ?.filter(Boolean)
    ?.map((activeProductsId) => Number(activeProductsId))

  return activeProductsIds ?? []
}

/**
 * Gets selected product IDs by type from parameters.
 */
export const getCombinedListingProductSelection = (
  combinedListingConfiguration: SanityCombinedListingConfiguration,
  parameters: Parameter[],
) => {
  const productSelection: Record<CombinedListingProductType, number | null> = {
    frame: null,
    glass: null,
    backboard: null,
    passepartout: null,
    spacer: null,
  }

  // Get existing product selection
  const activeProductsIds = getCombinedListingActiveProductsIds(parameters)
  activeProductsIds.forEach((activeProductsId) => {
    const result = findCombinedListingProduct(
      combinedListingConfiguration,
      activeProductsId,
    )

    if (result) {
      productSelection[result.type] = result.product.productID
    }
  })

  return productSelection
}
