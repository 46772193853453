import axios from 'axios'
import { useCallback, useContext, useMemo } from 'react'
import useSWR from 'swr'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import {
  type SanityProductGalleryPhoto,
  type SanityProductOption,
  type SanityProductOptionName,
  type SanityProductVariantFragment,
  type SanityProductFragment,
  type SanityProductOptionSetting,
  type SanityProductVariantOption,
} from '@data/sanity/queries/types/product'
import { type StoredUser } from './auth'
import { compareNumbers, hasObject, parseOptionalParameter } from './helpers'
import { usePrevious } from './hooks'
import { type Locale } from './language'
import { useUrlParameters } from './parameters'
import { SiteContext } from './site-context'

export interface Filter {
  name: string
  values: string[]
}

export interface FilterValue {
  name: string
  value: string
}

interface ProductInventoryRequest {
  localeHeader: Locale
  url: string
  id?: number
}

interface ProductInventoryVariant {
  id: number
  inStock: boolean
  lowStock: boolean
}

export interface ProductInventory {
  inStock: boolean
  lowStock: boolean
  variants: ProductInventoryVariant[]
}

interface ProductPriceOptions {
  productPrice?: number
  productComparePrice?: number
  variantPrice?: number
  variantComparePrice?: number
  user?: StoredUser
}

export const productLowStockAmount = 10

export const productVariantLowStockAmount = 5

export const colorOptionNames: string[] = ['Color']

export const sizeOptionNames: string[] = ['Size', 'Størrelse']

/**
 * Gets product gallery photos for selected variant.
 */
export const getProductGalleryPhotos = (
  photosets: SanityProductGalleryPhoto[],
  variant?: SanityProductVariantFragment,
): SanityImageFragment[] => {
  const variantPhotoset = photosets.find((photoset) => {
    const option = photoset.forOption
      ? {
          name: photoset.forOption.split(':')[0],
          value: photoset.forOption.split(':')[1],
        }
      : {}

    return option.value && variant && hasObject(variant.options, option)
  })

  if (variantPhotoset?.photos && variantPhotoset.photos.length > 0) {
    return variantPhotoset.photos
  }

  return photosets.find((photoset) => !photoset.forOption)?.photos ?? []
}

/**
 * Converts Sanity product or variant ID string into Shopify ID number.
 */
export const sanityProductIdToShopifyId = (sanityProductId: string) => {
  const shopifyProductId = sanityProductId.split('-')?.[1]

  if (!shopifyProductId) {
    return null
  }

  return Number(shopifyProductId)
}

/**
 * Gets product option label.
 */
export const getOptionLabel = (
  optionNames: SanityProductOptionName[],
  option: SanityProductOption,
) =>
  optionNames?.find(({ forOption }) => forOption === option.name)?.name ||
  option.name

/**
 * Gets default product option.
 */
export const getDefaultOption = (
  options: SanityProductOption[],
  optionSettings: SanityProductOptionSetting[],
): SanityProductVariantOption | undefined => {
  if (options.length === 0) {
    return
  }

  const firstOption = options?.[0]
  const defaultOption = {
    name: firstOption?.name,
    value: firstOption?.values?.[0],
    position: firstOption?.position,
  }

  if (optionSettings.length === 0) {
    return defaultOption
  }

  // Use first option setting to find default option
  const settingParts = optionSettings[0].forOption?.split(':')
  const name = settingParts?.[0]
  const value = settingParts?.[1]
  const position = options.find((option) => option.name === name)?.position

  if (
    typeof name === 'undefined' ||
    typeof value === 'undefined' ||
    typeof position === 'undefined'
  ) {
    return defaultOption
  }

  return { name, value, position }
}

/**
 * Gets a product variant by the default option.
 */
export const getVariantByDefaultOption = (
  variants: SanityProductVariantFragment[],
  defaultOption?: SanityProductVariantOption,
) => {
  if (!defaultOption) {
    return null
  }

  const variant = variants.find(({ options }) =>
    hasObject(options, defaultOption),
  )

  return variant ?? null
}

/**
 * Product with inventory data hook.
 */
export const useProductWithInventory = (
  locale: Locale,
  product?: SanityProductFragment,
): SanityProductFragment | undefined => {
  // Read product inventory
  const productInventoryRequest: ProductInventoryRequest = {
    localeHeader: locale,
    url: '/api/shopify/product-inventory',
    id: product?.productID,
  }
  const { data: productInventory } = useSWR<ProductInventory | undefined>(
    productInventoryRequest,
    async ({ localeHeader, url, id }: ProductInventoryRequest) => {
      if (!id) {
        return
      }

      const response = await axios.get<ProductInventory>(url, {
        params: {
          id,
        },
        headers: {
          'X-Locale': localeHeader,
        },
      })

      return response.data
    },
    {
      errorRetryCount: 3,
    },
  )

  return useMemo(() => {
    if (!product || !productInventory) {
      return product
    }

    const productVariants = product.variants ?? []

    return {
      ...product,
      inStock: productInventory.inStock,
      lowStock: productInventory.lowStock,
      variants: productVariants.map((productVariant) => {
        const productInventoryVariant = productInventory.variants.find(
          (variant) => variant.id === productVariant.variantID,
        )

        if (!productInventoryVariant) {
          return productVariant
        }

        return {
          ...productVariant,
          ...productInventoryVariant,
        }
      }),
    }
  }, [product, productInventory])
}

/**
 * Active product variant hook.
 */
export const useActiveVariant = (product?: SanityProductFragment) => {
  const { isRouteChanging } = useContext(SiteContext)

  const defaultVariantId = useMemo(() => {
    if (!product) {
      return null
    }

    const defaultOption = getDefaultOption(
      product.options,
      product.optionSettings ?? [],
    )
    const firstVariant = product.variants?.[0] ?? null
    const defaultVariant =
      getVariantByDefaultOption(product.variants ?? [], defaultOption) ??
      firstVariant
    return defaultVariant?.variantID ?? null
  }, [product])

  const [currentParameters, setCurrentParameters] = useUrlParameters([
    {
      name: 'variant',
      value: defaultVariantId ? `${defaultVariantId}` : null,
    },
  ])

  // Manage URL parameters
  const previousParameters = usePrevious(currentParameters)
  const activeParameters = useMemo(() => {
    return isRouteChanging && previousParameters
      ? previousParameters
      : currentParameters
  }, [currentParameters, previousParameters, isRouteChanging])

  // Find active variant
  const variantIds = useMemo(() => {
    return product?.variants?.map(({ variantID }) => variantID) ?? []
  }, [product?.variants])

  const activeVariantId = useMemo(() => {
    const parameterVariant = activeParameters.find(
      ({ name }) => name === 'variant',
    )
    const parameterVariantValue = parseOptionalParameter<string>(
      parameterVariant?.value,
    )
    const parameterVariantId = parameterVariantValue
      ? Number(parameterVariantValue)
      : null

    return variantIds.some((id) => id == parameterVariantId)
      ? parameterVariantId
      : defaultVariantId
  }, [activeParameters, defaultVariantId, variantIds])

  const activeVariant = useMemo(() => {
    return product?.variants?.find(
      ({ variantID }) => variantID === activeVariantId,
    )
  }, [product?.variants, activeVariantId])

  // Handle variant change
  const updateProductPageUrl = useCallback(
    (variantId: number) => {
      const isValidVariant = variantIds.some((id) => id === variantId)

      setCurrentParameters([
        ...activeParameters.filter(({ name }) => name !== 'variant'),
        {
          name: 'variant',
          value: isValidVariant ? `${variantId}` : `${defaultVariantId}`,
        },
      ])
    },
    [activeParameters, defaultVariantId, setCurrentParameters, variantIds],
  )

  return [activeVariant, updateProductPageUrl] as const
}

/**
 * Finds variant from active options and new option value.
 */
export const getVariantFromOptions = (
  variants: SanityProductVariantFragment[],
  activeOptions: SanityProductVariantOption[],
  optionName: string,
  optionValue: string,
) => {
  const newOptions = activeOptions.map((activeOption) =>
    activeOption.name === optionName
      ? {
          ...activeOption,
          value: optionValue,
        }
      : activeOption,
  )

  // Find variant that matches all new options
  return variants.find((variant) =>
    variant.options.every((variantOption) =>
      hasObject(newOptions, variantOption),
    ),
  )
}

/**
 * Gets product price and compare price.
 */
export const getProductPrices = ({
  productPrice,
  productComparePrice,
  variantPrice,
  variantComparePrice,
  user,
}: ProductPriceOptions) => {
  const price = variantPrice ?? productPrice

  const companyDiscountRate = user?.company?.percentDiscount
    ? user.company.percentDiscount / 100
    : undefined
  const priceWithCompanyDiscount =
    price && companyDiscountRate ? price * (1 - companyDiscountRate) : undefined
  const comparePrice = [
    variantComparePrice,
    productComparePrice,
    priceWithCompanyDiscount,
  ].reduce(
    (previousValue, currentValue) =>
      previousValue && previousValue > 0 ? previousValue : currentValue,
    0,
  )

  return [price, comparePrice] as const
}

/**
 * Sorts products in given order of product IDs.
 */
export const getSortedProducts = (
  products: SanityProductFragment[],
  productIds: string[],
) => {
  // Map product IDs to product indices in the sorted ID array
  const productIdIndexMap: Record<string, number> = {}

  productIds.forEach((productId, index) => {
    productIdIndexMap[productId] = index
  })

  return products.sort((product1, product2) => {
    const index1 = productIdIndexMap[product1._id]
    const index2 = productIdIndexMap[product2._id]

    if (typeof index1 === 'undefined' || typeof index2 === 'undefined') {
      return 0
    }

    return compareNumbers(index1, index2)
  })
}

/**
 * Gets product's default variant ID.
 */
export const getDefaultVariantId = (product: SanityProductFragment) => {
  if (!product) {
    return
  }

  const defaultOption = getDefaultOption(
    product.options,
    product.optionSettings ?? [],
  )
  const firstVariant = product.variants?.[0]
  const defaultVariant = getVariantByDefaultOption(
    product.variants ?? [],
    defaultOption,
  )
  const variant = defaultVariant ?? firstVariant

  return variant?.variantID
}

/**
 * Gets product's active variant.
 */
export const getActiveVariant = (
  product: SanityProductFragment,
  selectedVariantId?: number,
  defaultVariantId?: number,
) => {
  const variantIds =
    product?.variants?.map((variant) => variant.variantID) ?? []
  const isSelectedVariantValid = variantIds.some(
    (id) => id == selectedVariantId,
  )
  const activeVariantId = isSelectedVariantValid
    ? selectedVariantId
    : defaultVariantId

  return product?.variants?.find(
    (variant) => variant.variantID === activeVariantId,
  )
}
