import cx from 'classnames'
import { useCallback, useContext, useMemo } from 'react'

import { CartContext } from '@lib/cart/context'
import { type LineItem } from '@lib/cart/types'
import {
  getCombinedListingLineIds,
  getCombinedListingMergedLineItems,
} from '@lib/product/combined-listing'
import { StringsContext } from '@lib/strings-context'

import CartEmpty from './empty'
import CartLineItem from './line-item'

interface CartItemsProps {
  lineItems: LineItem[]
  className?: string
  itemClassName?: string
}

const CartLineItems = ({
  lineItems,
  className,
  itemClassName,
}: CartItemsProps) => {
  const { toggleCart, updateCartItem, removeItemFromCart } =
    useContext(CartContext)
  const strings = useContext(StringsContext)

  const mergedLineItems = useMemo(
    () => getCombinedListingMergedLineItems(strings, lineItems),
    [lineItems, strings],
  )

  /**
   * Handles updating product or combined listing quantity in cart.
   */
  const handleUpdateItem = useCallback(
    (lineId: string, quantity: number) => {
      const combinedListingLineIds = getCombinedListingLineIds(
        lineItems,
        lineId,
      )
      updateCartItem(combinedListingLineIds ?? [lineId], quantity)
    },
    [lineItems, updateCartItem],
  )

  /**
   * Handles removing product or combined listing from cart.
   */
  const handleRemoveItem = useCallback(
    (lineId: string) => {
      const combinedListingLineIds = getCombinedListingLineIds(
        lineItems,
        lineId,
      )
      removeItemFromCart(combinedListingLineIds ?? [lineId])
    },
    [lineItems, removeItemFromCart],
  )

  if (lineItems.length === 0) {
    return <CartEmpty />
  }

  return (
    <div className={cx('flex flex-col gap-y-5', className)}>
      {mergedLineItems.map((lineItem) => (
        <CartLineItem
          key={lineItem.lineId}
          lineId={lineItem.lineId}
          price={lineItem.price}
          options={lineItem.options}
          quantity={lineItem.quantity}
          product={lineItem.product}
          productUrl={lineItem.productUrl}
          onUpdateItem={handleUpdateItem}
          onRemoveItem={handleRemoveItem}
          onClose={() => toggleCart(false)}
          className={itemClassName}
        />
      ))}
    </div>
  )
}

export default CartLineItems
